import React, { useState } from 'react'
import { Link } from "gatsby"
import Logo from "./Logo"
import useWindowSize from "../styles/useWindowSize"


const Header = () => {
  const [width, height] = useWindowSize()
  let menu = () => {
    if (width < height || width <= 767) {
      return <VerticaleMenu />
    } else {
      return <HorizontalMenu />
    }
  }
  return (
    <header>
      {menu()}
    </header>
  )
}

interface ItemProps {
  to: string,
  title: string,
  className?: string
}

const Item: React.FC<ItemProps> = ({ to, title, className }) => (
  <p className={className + " has-text-centered"}>
    <Link to={to}>{title}</Link>
  </p>
)


const HorizontalMenu = () => {
  return (
    <section className="section pareo-header">
      <nav className="pareo-menu">
        <Item className="pareo-item" to="/" title="Accueil" />
        <Item className="pareo-item" to="/apropos/" title="A propos" />
        <Item className="pareo-item" to="/galeries/" title="Galeries" />
        <div className="pareo-item">
          <Logo />
        </div>
        <Item className="pareo-item" to="/blog/" title="Blog" />
        <Item className="pareo-item" to="/plaquette/" title="Tarifs" />
        <Item className="pareo-item" to="/contact/" title="Contact" />
      </nav>
    </section>
  )
}

const VerticaleMenu = () => {
  const [isActif, setActif] = useState(false)

  const handleClick = () => {
    setActif(isActif => !isActif)
  }

  const menu = () => {
    if (isActif) {
      return (
        <div className="navbar-menu is-active" id="navMenu">
          <div className="navbar-start">
            <Item className="navbar-item" to="/" title="Accueil" />
            <Item className="navbar-item" to="/apropos/" title="A propos" />
            <Item className="navbar-item" to="/galeries/" title="Galeries" />
            <Item className="navbar-item" to="/blog/" title="Blog" />
            <Item className="navbar-item" to="/plaquette/" title="Tarifs" />
            <Item className="navbar-item" to="/contact/" title="Contact" />
          </div>{" "}
        </div>
      )
    } else {
      return <div className="navbar-menu " id="navMenu"></div>
    }
  }
  return (
    <section className="pareo-header">
      <nav>
        <div className="pareo-item">
          <Logo />
        </div>
        <a
          onClick={handleClick}
          role="button"
          className={isActif ? "navbar-burger is-active" : "navbar-burger "}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </nav>
      {menu()}
    </section>
  )
}


export default Header
