
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'

const Logo = () => {
    return (<StaticQuery query={graphql`
    query LogoQuery {
      allStrapiConfiguration {
        nodes {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 800)
              }
            }
          }
        }
      }
    }
     `}

        render={data => {
            return (<GatsbyImage className="" image={data.allStrapiConfiguration.nodes[0].logo[0].localFile.childImageSharp.gatsbyImageData} alt="logo" />)
        }}
    />)
}

export default Logo


