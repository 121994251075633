import React from "react"
import {  graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


const Footer = () => (
  <StaticQuery query={graphql`
  query FooterQuery {
    allStrapiLien {
      nodes {
        Logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
        Titre
        Url
      }
    }
    strapiConfiguration {
      infos {
        Titre
        Slug
      }
      Siren
    }
  }`}

    render={data => (
      <div className="pareo-footer">
        <nav className="columns ">
          <div className="column is-3 has-text-centered">
            <div className="container">
              <p className="title is-3">
                <u>Informations</u>
              </p>
              <div>
                {
                  data.strapiConfiguration.infos.map((info:any) =>{
                    return (
                      <p className="subtitle is-6 has-text-centered">
                        <Link to={"/information/"+info.Slug}>{info.Titre}</Link>
                      </p>
                    )
                  })
                }
              
              
            </div>
            </div>
          </div>
          <div className="column has-text-centered">
            <div className="container">
              <p className="title is-3">
                <Link to="/apropos/">
                  <u>À propos de moi</u>
                </Link>
              </p>
              <div>
                <p className="subtitle is-6 has-text-centered">
                  Photographe de vie en région PACA, j'aime prendre des photos qui
                  vous ressemblent en toute convivialité et simplicité. N'hésitez
                pas à <Link to="/contact/">me contacter</Link> pour plus de
                renseignements.
              </p>
              </div>
            </div>
          </div>

          <div className="column is-3 has-text-centered">
            <div className="container">
              <p className="title is-3">
                {" "}
                <a
                  href="https://www.mariages.net/photo-mariage/pare-olivia--e159002"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-mce-href="https://www.mariages.net/photo-mariage/pare-olivia--e159002"
                >
                  <u>Recommandations</u>
                </a>
              </p>
              <div>
                <div>
                  <div className="row" style={{ display: "block" }} />
                  <div
                    className="ge-content ge-content-type-tinymce"
                    data-ge-content-type="tinymce"
                  >
                    <p>
                      <a
                        title="Suggéré sur www.mariages.net"
                        href="https://www.mariages.net/photo-mariage/pare-olivia--e159002"
                        data-mce-href="https://www.mariages.net/photo-mariage/pare-olivia--e159002"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          id="wp-rated-img"
                          src="https://cdn1.mariages.net/img/badges/2018/badge-simple_fr_FR@2x.jpg"
                          alt="Recommandé sur Mariages.net"
                          data-mce-src="https://cdn1.mariages.net/img/badges/2018/badge-simple_fr_FR@2x.jpg"
                          width="50%"
                          height="50%"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-2 has-text-centered">
            <div className="container">
              <p className="title is-3">
                <u>Suivez-moi</u>
              </p>
              <nav className="columns  is-mobile" style={{ flexWrap: "wrap" }}>
                {data.allStrapiLien.nodes.map( (lien:any) => (
                  <a
                    href={lien.Url}
                    key={lien.Url}
                    className="column has-text-centered"
                  >
                    <GatsbyImage image={lien.Logo.localFile.childImageSharp.gatsbyImageData} alt={"lien " + lien.Titre} />
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </nav>

        <footer className="footer">
          <div className="container ">
            <p className="has-text-centered">
              Copyright © {new Date().getFullYear()}
              {` `+'\n'}
            Paré Olivia, Photographe Lifestyle (Portrait, famille, mariage...)-
            N°Siren {data.strapiConfiguration.Siren} - TVA non applicable - Art. 293B du CGI
          </p>
          </div>
        </footer>
      </div>
    )
    }
  />
)

export default Footer
