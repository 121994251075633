import * as React from 'react'
import '../styles/layout.scss'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <div className={className}>{children}</div>
  </>
)

export default LayoutRoot
